// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconWallet,
    IconShirt,
    IconTriangleSquareCircle,
    IconBasket,
    IconBuildingStore
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconWallet,
    IconShirt,
    IconTriangleSquareCircle,
    IconBasket,
    IconBuildingStore
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'catalog',
            title: <FormattedMessage id="catalog" />,
            type: 'item',
            url: '/dashboard/catalog',
            icon: icons.IconShirt,
            breadcrumbs: true
        },
        // {
        //     id: 'templates',
        //     title: <FormattedMessage id="templates" />,
        //     type: 'item',
        //     url: '/dashboard/template',
        //     icon: icons.IconTriangleSquareCircle,
        //     breadcrumbs: true
        // },
        {
            id: 'purchases',
            title: <FormattedMessage id="purchases" />,
            type: 'item',
            url: '/dashboard/purchase',
            icon: icons.IconBasket,
            breadcrumbs: true,
            children: [
                {
                    id: 'accordion',
                    title: <FormattedMessage id="accordion" />,
                    type: 'item',
                    url: '/basic/accordion',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'stores',
            title: <FormattedMessage id="stores" />,
            type: 'item',
            url: '/dashboard/store',
            icon: icons.IconBuildingStore,
            breadcrumbs: true
        },
        {
            id: 'wallet',
            title: <FormattedMessage id="wallet" />,
            type: 'item',
            url: '/dashboard/wallet',
            icon: icons.IconWallet,
            breadcrumbs: true
        }
        // {
        //     id: 'default',
        //     title: <FormattedMessage id="default" />,
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'analytics',
        //     title: <FormattedMessage id="analytics" />,
        //     type: 'item',
        //     url: '/dashboard/analytics',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;
