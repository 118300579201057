// third-party
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
import { config } from '../../config';
import { openSnackbar } from 'store/slices/snackbar';
import { addTemplate } from 'store/slices/cart';

const initialState = {
    error: null,
    template: null,
    templates: [],
    canvas: null,
    canvases: [],
    placement: null,
    isTemplateLoading: false
};

const slice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // SET VARIANTS
        setVariantsSuccess(state, action) {
            state.variants = action.payload;
            state.error = null;
        },
        // SET TECNIQUE
        setTechniqueSuccess(state, action) {
            state.technique = action.payload;
            state.error = null;
        },
        // CREATE CANVAS
        createTemplateSuccess(state, action) {
            state.template = action.payload;
            state.canvases = action.payload.canvases;
            state.error = null;
        },
        // GET TEMPLATES
        getTemplatesSuccess(state, action) {
            state.templates = action.payload;
            state.error = null;
        },
        // SET LOADING
        setLoadingSuccess(state, action) {
            state.isTemplateLoading = action.payload;
            state.error = null;
        },
        // SET TECNIQUE
        getPlacementSuccess(state, action) {
            state.placement = action.payload;
            state.isTemplateLoading = false;
            state.error = null;
        },
        // RESET TEMPLATE
        resetTemplateSuccess(state) {
            state = initialState;
        }
    }
});

// Reducer
export default slice.reducer;

export function getLayer(id) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'payment_methods';
            // eslint-disable-next-line prefer-template
            const url = apiRoot + path + '/' + id;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getPlacementSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getLayers() {
    return async () => {
        try {
            // const response = await axios.get('/api/products/list');
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'payment_methods/user?email=';
            const url = apiRoot + path + email;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getPaymentMethodsSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createTemplate(variant, canvases, quantity, price) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const payload = { variantId: variant.id, canvases, quantity };
            const response = await axios.post(`${apiRoot}templates/generate`, payload, { headers: { Authorization: bearer + token } });
            // Kontrol etmek istediğiniz şartı burada ekleyin
            if (!response.data.canvases) {
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: 'Add the item to cart again',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
            dispatch(slice.actions.createTemplateSuccess(response.data));
            dispatch(addTemplate(response.data, quantity, price));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                // console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function getPlacement(id) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'placements';
            // eslint-disable-next-line prefer-template
            const url = apiRoot + path + '/' + id;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getPlacementSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function resetTemplate() {
    return async () => {
        try {
            dispatch(slice.actions.resetTemplateSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'An error occured',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    };
}

export function getTemplates() {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'templates/filter?email=';
            const url = apiRoot + path + email;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getTemplatesSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
