// third-party
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
import { config } from '../../config';
import { openSnackbar } from 'store/slices/snackbar';

const initialState = {
    error: null,
    paymentMethods: [],
    paymentMethod: [],
    balance: 0,
    transactions: [],
    isLoading: true
};

const slice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // GET PAYMENT METHOD
        getPaymentMethodSuccess(state, action) {
            state.paymentMethod = action.payload;
        },
        // GET PAYMENT METHODS
        getPaymentMethodsSuccess(state, action) {
            state.paymentMethods = action.payload;
        },
        // ADD BANK ACCOUNT
        addBankAccountSuccess(state, action) {
            state.paymentMethods = action.payload;
        },
        // ADD CREDIT CARD
        addCreditCardSuccess(state, action) {
            state.paymentMethods = action.payload;
            state.isLoading = false;
        },
        // VERIFY PAYMENT METHOD
        getVerifySuccess(state, action) {
            state.paymentMethods = action.payload;
        },
        // REMOVE PAYMENT METHOD
        getRemoveSuccess(state, action) {
            state.paymentMethods = action.payload;
        },
        // ADD FUNDS
        getAddFundsSuccess(state, action) {
            state.balance = action.payload.balance / 100;
            state.transactions = action.payload.transactions;
        },
        // GET BALANCE
        getBalanceSuccess(state, action) {
            state.balance = action.payload[0].balance / 100;
        },
        // GET TRANSACTIONS
        getTransactionsSuccess(state, action) {
            state.transactions = action.payload;
        },
        // SET LOADING
        setLoadingSuccess(state, action) {
            state.isLoading = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addBankAccount(type, routing, account, accountHolder, accountType) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const response = await axios.post(
                // eslint-disable-next-line prefer-template
                apiRoot + 'payment_methods/create_payment_method',
                { type, routing, account, email, accountHolder, accountType },
                { headers: { Authorization: bearer + token } }
            );
            dispatch(slice.actions.addBankAccountSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Added Bank Account Successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } catch (error) {
            dispatch(slice.actions.setLoadingSuccess(false));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log('Error message:', error.message);
            }
            // console.log('Error config:', error.config);
        }
    };
}

export function addCreditCard(token) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true));
            const firebaseToken = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const response = await axios.post(
                // eslint-disable-next-line prefer-template
                apiRoot + 'payment_methods/create_payment_method',
                { email, token },
                { headers: { Authorization: bearer + firebaseToken } }
            );
            dispatch(slice.actions.addCreditCardSuccess(response.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Added Credit Card Successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } catch (error) {
            dispatch(slice.actions.setLoadingSuccess(false));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log('Error message:', error.message);
            }
            // console.log('Error config:', error.config);
        }
    };
}

export function getPaymentMethod(id) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'payment_methods';
            // eslint-disable-next-line prefer-template
            const url = apiRoot + path + '/' + id;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getPaymentMethodSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPaymentMethods() {
    return async () => {
        try {
            // const response = await axios.get('/api/products/list');
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'payment_methods/user?email=';
            const url = apiRoot + path + email;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getPaymentMethodsSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function verify(setupIntentID, descriptorCode) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'payment_methods/verify';
            const url = apiRoot + path;
            const response = await axios.post(
                url,
                { setupIntentID, descriptorCode, email },
                { headers: { Authorization: bearer + token } }
            );
            dispatch(slice.actions.setLoadingSuccess(false));
            dispatch(slice.actions.getVerifySuccess(response.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Verifed!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } catch (error) {
            dispatch(slice.actions.setLoadingSuccess(false));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log('Error message:', error.message);
            }
            // console.log('Error config:', error.config);
        }
    };
}

export function remove(id) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'payment_methods/remove';
            const url = apiRoot + path;
            const response = await axios.post(url, { id, email }, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getRemoveSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Removed!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } catch (error) {
            dispatch(slice.actions.setLoadingSuccess(false));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log('Error message:', error.message);
            }
            // console.log('Error config:', error.config);
        }
    };
}

export function addFunds(id, amount) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            // find user id from user email address
            // eslint-disable-next-line prefer-template
            const path1 = 'users?id=&email=' + email;
            const url1 = apiRoot + path1;
            const response1 = await axios.get(url1, { headers: { Authorization: bearer + token } });
            const walletID = response1.data[0].wallet_id;
            // eslint-disable-next-line prefer-template
            const path = 'wallet/' + walletID + '/add_funds';
            const url = apiRoot + path;
            const response = await axios.post(url, { id, amount, email }, { headers: { Authorization: bearer + token } });
            // id'den payment metodunun type bul
            const path2 = 'payment_methods';
            // eslint-disable-next-line prefer-template
            const url2 = apiRoot + path2 + '/' + id;
            const response2 = await axios.get(url2, { headers: { Authorization: bearer + token } });
            const type = response2.data.type;
            dispatch(slice.actions.getAddFundsSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
            // if ach, message will be different
            if (type === 'card') {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Added!',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: "We're processing your transaction. It may take up to 1-3 business days to appear in your account.",
                        variant: 'alert',
                        alert: {
                            color: 'warning'
                        }
                    })
                );
            }
        } catch (error) {
            dispatch(slice.actions.setLoadingSuccess(false));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log('Error message:', error.message);
            }
            // console.log('Error config:', error.config);
        }
    };
}

export function getBalance() {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            // eslint-disable-next-line prefer-template
            const path = 'users?id=&email=' + email;
            const url = apiRoot + path;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getBalanceSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Couldn't get the account balance!",
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
            dispatch(slice.actions.setLoadingSuccess(false));
        }
    };
}

export function getTransactions() {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            // find user id from user email address
            // eslint-disable-next-line prefer-template
            const path1 = 'users?id=&email=' + email;
            const url1 = apiRoot + path1;
            const response1 = await axios.get(url1, { headers: { Authorization: bearer + token } });
            const walletID = response1.data[0].wallet_id;
            // eslint-disable-next-line prefer-template
            const path = `transactions?type=&wallet=${walletID}`;
            const url = apiRoot + path;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getTransactionsSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Couldn't get transactions!",
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
            dispatch(slice.actions.setLoadingSuccess(false));
        }
    };
}

export function setLoading(bool) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(bool));
        } catch (error) {
            console.log(error);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    };
}
