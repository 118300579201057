// third-party
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import { dispatch } from '../index';
import { config } from '../../config';
import { openSnackbar } from 'store/slices/snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    products: [],
    product: null,
    customizableVariants: null,
    variant: null,
    relatedProducts: [],
    reviews: [],
    addresses: null,
    countries: [],
    technique: null,
    canvasBackground: null,
    isLoading: true
};

const slice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },

        // GET PRODUCTS
        getProductsSuccess(state, action) {
            state.products = action.payload;
        },

        // FILTER PRODUCTS
        filterProductsSuccess(state, action) {
            state.products = action.payload;
        },

        // GET PRODUCT
        getProductSuccess(state, action) {
            state.product = action.payload;
        },

        // GET RELATED PRODUCTS
        getRelatedProductsSuccess(state, action) {
            state.relatedProducts = action.payload;
        },

        // GET PRODUCT REVIEWS
        getProductReviewsSuccess(state, action) {
            state.reviews = action.payload;
        },

        // GET ADDRESSES
        getAddressesSuccess(state, action) {
            state.addresses = action.payload;
        },

        // ADD ADDRESS
        addAddressSuccess(state, action) {
            state.addresses = action.payload;
        },

        // EDIT ADDRESS
        editAddressSuccess(state, action) {
            state.addresses = action.payload;
        },

        // DELETE ADDRESS
        deleteAddressSuccess(state, action) {
            state.addresses = action.payload;
        },

        // GET VARIANT
        getVariantSuccess(state, action) {
            state.variant = action.payload;
        },
        // SET CUSTOMAZIBLE VARIANTS
        setCustomizableVariantsSuccess(state, action) {
            state.customizableVariants = action.payload;
        },

        // GET COUNTRIES
        getCountriesSuccess(state, action) {
            state.countries = action.payload;
        },
        // SET TECNIQUE
        setTechniqueSuccess(state, action) {
            state.technique = action.payload;
        },
        // SET CANVAS BACKGROUND ADDRESS
        setCanvasBackgroudSuccess(state, action) {
            state.canvasBackground = action.payload;
        },
        // SET LOADING
        setIsLoadingSuccess(state, action) {
            state.isLoading = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProducts() {
    return async () => {
        try {
            // const response = await axios.get('/api/products/list');
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'products-basic';
            const url = apiRoot + path;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getProductsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function filterProducts(filter) {
    return async () => {
        try {
            const response = await axios.post('/api/products/filter', { filter });
            dispatch(slice.actions.filterProductsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProduct(id) {
    return async () => {
        try {
            dispatch(slice.actions.setIsLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'products/';
            const url = apiRoot + path + id;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getProductSuccess(response.data));
            dispatch(slice.actions.setIsLoadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function getRelatedProducts(id) {
    return async () => {
        try {
            const response = await axios.post('/api/product/related', { id });
            dispatch(slice.actions.getRelatedProductsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProductReviews() {
    return async () => {
        try {
            const response = await axios.get('/api/review/list');
            dispatch(slice.actions.getProductReviewsSuccess(response.data.productReviews));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAddresses(user) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            // eslint-disable-next-line prefer-template
            const url1 = config.API_SERVER + 'users?id=&email=' + user.email;
            const response1 = await axios.get(url1, { headers: { Authorization: bearer + token } });
            const userID = response1.data[0].id;
            // eslint-disable-next-line prefer-template
            const path = 'shipping_addresses?is_active=true&user=' + userID;
            const url = apiRoot + path;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getAddressesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function addAddress(address, user) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            // eslint-disable-next-line prefer-template
            const userUrl = config.API_SERVER + 'users?id=&email=' + user.email;
            const userUrlResponse = await axios.get(userUrl, { headers: { Authorization: bearer + token } });
            const userId = userUrlResponse.data[0].id;
            // eslint-disable-next-line prefer-template
            const shippingAddressesUrl = config.API_SERVER + 'shipping_addresses';
            const addressData = {
                name: address.name,
                destination: address.destination,
                street1: address.street,
                street2: address.building,
                city: address.city,
                state: address.state,
                zip_code: address.post,
                user: userId,
                country: address.country
            };
            await axios.post(shippingAddressesUrl, addressData, { headers: { Authorization: bearer + token } });
            // eslint-disable-next-line prefer-template
            const url = config.API_SERVER + 'shipping_addresses?is_active=true&user=' + userId;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.addAddressSuccess(response.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Address created!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function editAddress(address) {
    return async () => {
        try {
            console.log('editAddress()', address);
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            // eslint-disable-next-line prefer-template
            const shippingAddressURL = config.API_SERVER + 'shipping_addresses/' + address.id;
            const shippingAddressData = {
                name: address.name,
                destination: address.destination,
                street1: address.street,
                street2: address.building,
                city: address.city,
                state: address.state,
                country: address.country,
                zip_code: address.post
            };
            await axios.put(shippingAddressURL, shippingAddressData, { headers: { Authorization: bearer + token } });
            // eslint-disable-next-line prefer-template
            const shippingAddressesURL = config.API_SERVER + 'shipping_addresses?is_active=true&user=' + address.user;
            const shippingAddressesURLResponse = await axios.get(shippingAddressesURL, { headers: { Authorization: bearer + token } });
            // const response = await axios.post('/api/address/edit', address);
            dispatch(slice.actions.editAddressSuccess(shippingAddressesURLResponse.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Address edited!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function deleteAddress(address) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            // eslint-disable-next-line prefer-template
            const url = config.API_SERVER + 'shipping_addresses/' + address.id;
            const shippingAddressData = { is_active: false, country: address.country };
            const response = await axios.put(url, shippingAddressData, { headers: { Authorization: bearer + token } });
            // eslint-disable-next-line prefer-template
            const url2 = config.API_SERVER + 'shipping_addresses?is_active=true&user=' + response.data.user;
            const response2 = await axios.get(url2, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.deleteAddressSuccess(response2.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Address removed!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function getVariant(productID, valueOne, valueTwo, valueThree) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            // eslint-disable-next-line prefer-template
            const url =
                // eslint-disable-next-line prefer-template
                config.API_SERVER +
                'variants?value1=' +
                valueOne +
                '&value2=' +
                valueTwo +
                '&value3=' +
                valueThree +
                '&product=' +
                productID;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            if (response.data[0]) {
                // to do: make sure that it only returns one variant. attribute values must be disticnt
                dispatch(slice.actions.getVariantSuccess(response.data[0]));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function getCountries() {
    return async () => {
        try {
            // const response = await axios.get('/api/products/list');
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'countries';
            const url = apiRoot + path;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getCountriesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function selectTechnique(technique) {
    return async () => {
        try {
            dispatch(slice.actions.setTechniqueSuccess(technique));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function changeTechnique(variant, technique) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = `techniques/findVariantTechnique?technique=${technique}&variant=${variant}`;
            const url = apiRoot + path;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.setTechniqueSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function setCustomizableVariants(product, technique) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = `variants/filterVariants?technique=${technique}&product=${product}`;
            const url = apiRoot + path;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.setCustomizableVariantsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function setCanvasBackgroud(color) {
    return async () => {
        try {
            dispatch(slice.actions.setCanvasBackgroudSuccess(color));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}
