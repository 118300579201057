// third-party
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
import { config } from '../../config';
import { openSnackbar } from 'store/slices/snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    checkout: {
        loading: true,
        step: 0,
        products: [],
        subtotal: 0,
        discount: 0,
        shipping: 0,
        totalBeforeTax: 0,
        tax: 0,
        total: 0,
        billing_address: null,
        delivery: null,
        dimensions: null,
        rates: [],
        rate: null,
        payment: {
            type: 'free',
            method: 'cod',
            card: ''
        },
        purchase: null,
        complete: false
    }
};

const slice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.checkout.loading = false;
        },

        // ADD PRODUCT
        addProductSuccess(state, action) {
            state.checkout.products = action.payload.items;
            state.checkout.subtotal = action.payload.subtotal;
            state.checkout.total = action.payload.subtotal + state.checkout.shipping + state.checkout.discount;
        },

        // REMOVE PRODUCT
        removeProductSuccess(state, action) {
            state.checkout.products = action.payload.items;
            // state.checkout.subtotal += -action.payload.subtotal;
            // state.checkout.total += -action.payload.subtotal;
            state.checkout.subtotal = action.payload.subtotal;
            state.checkout.total = action.payload.subtotal + state.checkout.shipping + state.checkout.discount;
        },

        // UPDATE PRODUCT
        updateProductSuccess(state, action) {
            state.checkout.products = action.payload.items;
            // state.checkout.subtotal = state.checkout.subtotal - action.payload.oldSubTotal + action.payload.subtotal;
            // state.checkout.total = state.checkout.total - action.payload.oldSubTotal + action.payload.subtotal;
            state.checkout.subtotal = action.payload.subtotal;
            state.checkout.total = action.payload.subtotal + state.checkout.shipping + state.checkout.discount;
        },

        // SET STEP
        setStepSuccess(state, action) {
            state.checkout.step = action.payload;
        },

        // SET NEXT STEP
        setNextStepSuccess(state) {
            state.checkout.step += 1;
        },

        // SET BACK STEP
        setBackStepSuccess(state) {
            state.checkout.step -= 1;
        },

        // SET SHIPPING ADDRESS
        setDeliveryAddressSuccess(state, action) {
            state.checkout.delivery = action.payload;
        },

        // RESET SHIPPING ADDRESS
        resetDeliveryAddressSuccess(state, action) {
            state.checkout.delivery = action.payload;
        },

        // SET DISCOUNT
        setDiscountSuccess(state, action) {
            let difference = 0;
            if (state.checkout.discount > 0) {
                difference = state.checkout.discount;
            }

            state.checkout.discount = action.payload.amount;
            state.checkout.total = state.checkout.total + difference - action.payload.amount;
        },

        // SET SHIPPING CHARGE
        setShippingChargeSuccess(state, action) {
            state.checkout.rate = action.payload;
            state.checkout.shipping = action.payload.my_rate;
            state.checkout.total = action.payload.my_rate + state.checkout.subtotal + state.checkout.discount;
            state.checkout.loading = false;
        },

        // SET PAYMENT METHOD
        setPaymentMethodSuccess(state, action) {
            state.checkout.payment = {
                ...state.checkout.payment,
                method: action.payload.method
            };
        },

        // SET PAYMENT CARD
        setPaymentCardSuccess(state, action) {
            state.checkout.payment = {
                ...state.checkout.payment,
                card: action.payload.card
            };
        },

        // RESET CART
        resetCartSuccess(state) {
            state.checkout = initialState.checkout;
        },
        // RESET RATE
        resetRateSuccess(state) {
            const cartSubTotal = state.checkout.subtotal;
            const cartDiscount = state.checkout.discount;
            state.checkout.shipping = initialState.checkout.shipping;
            state.checkout.total = cartSubTotal + cartDiscount;
            state.checkout.rate = initialState.checkout.rate;
        },
        // GET CART
        getCartSuccess(state, action) {
            state.checkout.step = initialState.checkout.step;
            state.checkout.products = action.payload.items;
            state.checkout.billing_address = action.payload.billing_address;
            // state.checkout.dimensions = initialState.checkout.dimensions;
            state.checkout.subtotal = action.payload.subtotal;
            state.checkout.total = action.payload.subtotal + state.checkout.shipping + state.checkout.discount;
        },

        // GET PARCEL DIMENSIONS
        getDimensionsSuccess(state, action) {
            state.checkout.dimensions = action.payload;
        },

        // GET RATES
        getRatesSuccess(state, action) {
            state.checkout.rates = action.payload;
        },

        // CREATE ORDER
        createOrderSuccess(state) {
            state.checkout.complete = true;
        },

        // GET ORDER
        getPurchaseSuccess(state, action) {
            state.checkout.purchase = action.payload;
        },

        // SET LOADING
        setLoadingSuccess(state, action) {
            state.checkout.loading = action.payload;
        },

        // GET BILLING ADDRESS
        getBillingAddressSuccess(state, action) {
            state.checkout.billing_address = action.payload;
        },

        // ADD BILLING ADDRESS
        addBillingAddressSuccess(state, action) {
            state.checkout.billing_address = action.payload;
        },

        // EDIT BILLING ADDRESS
        editBillingAddressSuccess(state, action) {
            state.checkout.billing_address = action.payload;
        },

        // DELETE BILLING ADDRESS
        deleteBillingAddressSuccess(state, action) {
            state.checkout.billing_address = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDimensions() {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            // eslint-disable-next-line prefer-template
            const url = config.API_SERVER + 'users?id=&email=' + email;
            // eslint-disable-next-line prefer-template
            const response = await axios.get(url, { headers: { Authorization: 'Bearer ' + token } });
            const cartID = response.data[0].cart_id;
            // eslint-disable-next-line prefer-template
            const cartURL = config.API_SERVER + 'carts/' + cartID + '/get_dimensions';
            // eslint-disable-next-line prefer-template
            const cartResponse = await axios.get(cartURL, { headers: { Authorization: 'Bearer ' + token } });
            dispatch(slice.actions.getDimensionsSuccess(cartResponse.data));
            dispatch(slice.actions.setLoadingSuccess(true)); // must be true while checking dimensions
        } catch (error) {
            dispatch(slice.actions.setLoadingSuccess(false));
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addProduct(product) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            // eslint-disable-next-line
            const path2 = 'variants?product=' + product.id +'&value1=' + product.value1 + '&value2=' + product.value2;
            const url2 = apiRoot + path2;
            // once variant idisini bulup sonra post et
            const variantResponse = await axios.get(url2, { headers: { Authorization: bearer + token } });
            const path = 'variant_cartitems';
            const url = apiRoot + path;
            const productData = { variant: variantResponse.data[0].id, quantity: product.quantity, price: variantResponse.data[0].price };
            const response = await axios.post(url, productData, { headers: { Authorization: bearer + token } });
            // bundan sonra carttaki butun itemlari return edip productsin icine yaz
            // eslint-disable-next-line
            const path3 = 'carts/' + response.data.cart;
            const url3 = apiRoot + path3;
            const cartResponse = await axios.get(url3, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.addProductSuccess(cartResponse.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Added to cart!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(getDimensions()); // update dimension with this code
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log('Error message:', error.message);
            }
        }
    };
}

export function removeProduct(id, cart) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            // eslint-disable-next-line
            const path = 'cartitems/' + id;
            const url = apiRoot + path;
            await axios.delete(url, { headers: { Authorization: bearer + token } });
            // eslint-disable-next-line
            const path2 = 'carts/' + cart;
            const url2 = apiRoot + path2;
            const cartResponse = await axios.get(url2, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.removeProductSuccess(cartResponse.data));
            dispatch(getDimensions()); // update dimension with this code
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProduct(id, qty) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            // find user id from user email address
            // eslint-disable-next-line prefer-template
            const path1 = 'users?id=&email=' + email;
            const url1 = apiRoot + path1;
            const response1 = await axios.get(url1, { headers: { Authorization: bearer + token } });
            const cartID = response1.data[0].cart_id;
            // eslint-disable-next-line
            const path = 'cartitems/' + id;
            const url = apiRoot + path;
            const productData = { cart: cartID, quantity: qty };
            const response = await axios.put(url, productData, { headers: { Authorization: bearer + token } });
            // eslint-disable-next-line
            const path3 = 'carts/' + response.data.cart;
            const url3 = apiRoot + path3;
            const cartResponse = await axios.get(url3, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.updateProductSuccess(cartResponse.data));
            dispatch(getDimensions()); // update dimension with this code
        } catch (error) {
            console.log(error.response);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setStep(step) {
    return () => {
        dispatch(slice.actions.setStepSuccess(step));
    };
}

export function setNextStep() {
    return () => {
        dispatch(slice.actions.setNextStepSuccess({}));
    };
}

export function setBackStep() {
    return () => {
        dispatch(slice.actions.setBackStepSuccess({}));
    };
}

export function setDeliveryAddress(address) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            // find user id from user email address
            // eslint-disable-next-line prefer-template
            const url1 = config.API_SERVER + 'users?id=&email=' + email;
            const response1 = await axios.get(url1, { headers: { Authorization: bearer + token } });
            const cartID = response1.data[0].cart_id;
            const userID = response1.data[0].id;
            // eslint-disable-next-line
            const url = config.API_SERVER + 'carts/' + cartID;
            const shippingAddressData = { shipping_address: address.id, user: userID };
            await axios.put(url, shippingAddressData, { headers: { Authorization: bearer + token } });
            // eslint-disable-next-line prefer-template
            const url2 = config.API_SERVER + 'shipping_addresses/' + address.id;
            const response2 = await axios.get(url2, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.setDeliveryAddressSuccess(response2.data));
            dispatch(setNextStep());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function resetDeliveryAddress() {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            // find user id from user email address
            // eslint-disable-next-line prefer-template
            const userURL = config.API_SERVER + 'users?id=&email=' + email;
            const userURLResponse = await axios.get(userURL, { headers: { Authorization: bearer + token } });
            const cartID = userURLResponse.data[0].cart_id;
            const userID = userURLResponse.data[0].id;
            // eslint-disable-next-line
            const cartURL = config.API_SERVER + 'carts/' + cartID;
            const shippingAddressData = { shipping_address: null, user: userID };
            await axios.put(cartURL, shippingAddressData, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.resetDeliveryAddressSuccess(null));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function setDiscount(code, total) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/discount', { code, total });
            dispatch(slice.actions.setDiscountSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setShippingCharge(rateId) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            // eslint-disable-next-line prefer-template
            const url = config.API_SERVER + 'rates/' + rateId;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.setShippingChargeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setPaymentMethod(method) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/payment-method', { method });
            dispatch(slice.actions.setPaymentMethodSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setPaymentCard(card) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/payment-card', { card });
            dispatch(slice.actions.setPaymentCardSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetCart() {
    return async () => {
        try {
            // const response = await axios.post('/api/cart/reset');
            dispatch(slice.actions.resetCartSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetRate() {
    return async () => {
        try {
            dispatch(slice.actions.resetRateSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCart() {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            // eslint-disable-next-line prefer-template
            const url = config.API_SERVER + 'users?id=&email=' + email;
            // eslint-disable-next-line prefer-template
            const response = await axios.get(url, { headers: { Authorization: 'Bearer ' + token } });
            const cartID = response.data[0].cart_id;
            const userID = response.data[0].id;
            // if the user has a cart continue using it. If user doens't have an create a new cart
            if (cartID) {
                // eslint-disable-next-line prefer-template
                const cartURL = config.API_SERVER + 'carts/' + cartID;
                // eslint-disable-next-line prefer-template
                const cartResponse = await axios.get(cartURL, { headers: { Authorization: 'Bearer ' + token } });
                dispatch(slice.actions.getCartSuccess(cartResponse.data));
            } else {
                // eslint-disable-next-line prefer-template
                const cartURL = config.API_SERVER + 'carts';
                const cartResponse = await axios.post(
                    cartURL,
                    { user: userID, is_active: true, mode: 'production' },
                    // eslint-disable-next-line prefer-template
                    { headers: { Authorization: 'Bearer ' + token } }
                );
                dispatch(slice.actions.getCartSuccess(cartResponse.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // // Error
            // if (error.response) {
            //     // The request was made and the server responded with a status code
            //     // that falls out of the range of 2xx
            //     console.log(error.response.data);
            //     // console.log(error.response.status);
            //     // console.log(error.response.headers);
            //     dispatch(slice.actions.hasError(error.response.data));
            //     dispatch(
            //         openSnackbar({
            //             open: true,
            //             // eslint-disable-next-line prefer-template
            //             // message: error.response.status + ' ' + error.response.statusText,
            //             message: error.response.data.message,
            //             variant: 'alert',
            //             alert: {
            //                 color: 'error'
            //             }
            //         })
            //     );
            // } else if (error.request) {
            //     // The request was made but no response was received
            //     // `error.request` is an instance of XMLHttpRequest in the
            //     // browser and an instance of
            //     // http.ClientRequest in node.js
            //     dispatch(
            //         openSnackbar({
            //             open: true,
            //             message: 'The request was made but no response was received',
            //             variant: 'alert',
            //             alert: {
            //                 color: 'error'
            //             }
            //         })
            //     );
            //     console.log(error.request);
            // } else {
            //     // Something happened in setting up the request that triggered an Error
            //     dispatch(
            //         openSnackbar({
            //             open: true,
            //             message: 'Something happened in setting up the request that triggered an error',
            //             variant: 'alert',
            //             alert: {
            //                 color: 'error'
            //             }
            //         })
            //     );
            // }
        }
    };
}

// pass the cart id here
export function getPurchase(id) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            // eslint-disable-next-line prefer-template
            const cartURL = `${config.API_SERVER}purchases/${id}`;
            const cartResponse = await axios.get(cartURL, { headers: { Authorization: `Bearer ${token}` } });
            dispatch(slice.actions.getPurchaseSuccess(cartResponse.data));
            dispatch(slice.actions.setLoadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setLoadingSuccess(false));
        }
    };
}

export function getRates(items, shippingAddress, shippingParcel) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            // eslint-disable-next-line prefer-template
            const url = config.API_SERVER + 'rates/create_rates';
            // eslint-disable-next-line prefer-template
            const response = await axios.post(
                url,
                { products: items, address: shippingAddress, parcel: shippingParcel },
                // eslint-disable-next-line prefer-template
                { headers: { Authorization: 'Bearer ' + token } }
            );
            dispatch(resetRate());
            dispatch(slice.actions.getRatesSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setLoadingSuccess(false));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        // message: error.response.status + ' ' + error.response.statusText,
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function createOrder(rate, delivery) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            // eslint-disable-next-line prefer-template
            const url = config.API_SERVER + 'users?id=&email=' + email;
            // eslint-disable-next-line prefer-template
            const response = await axios.get(url, { headers: { Authorization: 'Bearer ' + token } });
            const cartID = response.data[0].cart_id;
            // eslint-disable-next-line prefer-template
            const cartURL = config.API_SERVER + 'carts/' + cartID + '/create_order';
            const cartData = { cart_id: cartID, rate_id: rate?.id || null, delivery_id: delivery.id };
            // eslint-disable-next-line prefer-template
            await axios.post(cartURL, cartData, { headers: { Authorization: 'Bearer ' + token } });
            dispatch(slice.actions.createOrderSuccess());
            dispatch(resetCart());
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Thanks for your order!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        // message: error.response.status + ' ' + error.response.statusText,
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log('Error message:', error.message);
            }
        }
    };
}

export function getBillingAddress() {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            // eslint-disable-next-line prefer-template
            const url1 = config.API_SERVER + 'users?id=&email=' + email;
            const response1 = await axios.get(url1, { headers: { Authorization: bearer + token } });
            const billingAddress = response1.data[0].billing_address;
            dispatch(slice.actions.getBillingAddressSuccess(billingAddress));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function addBAddress(address) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            // eslint-disable-next-line prefer-template
            const userUrl = config.API_SERVER + 'users?id=&email=' + email;
            const userUrlResponse = await axios.get(userUrl, { headers: { Authorization: bearer + token } });
            const userId = userUrlResponse.data[0].id;
            // eslint-disable-next-line prefer-template
            const billingAddressesUrl = config.API_SERVER + 'billing_addresses';
            const addressData = {
                name: address.name,
                destination: address.destination,
                street1: address.street,
                street2: address.building,
                city: address.city,
                state: address.state,
                zip_code: address.post,
                user: userId,
                country: address.country
            };
            const response = await axios.post(billingAddressesUrl, addressData, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.addBillingAddressSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Billing address created!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setLoadingSuccess(false));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function editBAddress(address) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            // eslint-disable-next-line prefer-template
            const shippingAddressURL = config.API_SERVER + 'billing_addresses/' + address.id;
            const shippingAddressData = {
                name: address.name,
                street1: address.street,
                street2: address.building,
                city: address.city,
                state: address.state,
                country: address.country,
                zip_code: address.post
            };
            const response = await axios.put(shippingAddressURL, shippingAddressData, { headers: { Authorization: bearer + token } });
            // const response = await axios.post('/api/address/edit', address);
            dispatch(slice.actions.addBillingAddressSuccess(response.data));
            dispatch(slice.actions.setLoadingSuccess(false));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Billing address edited!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function deleteBAddress(address) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            // eslint-disable-next-line prefer-template
            const url = config.API_SERVER + 'shipping_addresses/' + address.id;
            const shippingAddressData = { is_active: false, country: address.country };
            const response = await axios.put(url, shippingAddressData, { headers: { Authorization: bearer + token } });
            // eslint-disable-next-line prefer-template
            const url2 = config.API_SERVER + 'shipping_addresses?is_active=true&user=' + response.data.user;
            const response2 = await axios.get(url2, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.deleteAddressSuccess(response2.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Address removed!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function addTemplate(template, qty, price) {
    return async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const apiRoot = config.API_SERVER;
            const path = 'template_cartitems';
            const url = apiRoot + path;
            const templateData = { template: template.id, quantity: qty, price: Number(price.toFixed(2)) };
            const response = await axios.post(url, templateData, { headers: { Authorization: bearer + token } });
            // bundan sonra carttaki butun itemlari return edip productsin icine yaz
            // eslint-disable-next-line
            const path3 = 'carts/' + response.data.cart;
            const url3 = apiRoot + path3;
            const cartResponse = await axios.get(url3, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.addProductSuccess(cartResponse.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Added to cart!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(getDimensions()); // update dimension with this code
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log('Error message:', error.message);
            }
        }
    };
}
