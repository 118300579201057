import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API, config } from '../config';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user.uid,
                                email: user.email,
                                name: user.displayName,
                                avatar: user.photoURL
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const createAccountOrSkip = async () => {
        try {
            const user = await new Promise((resolve) => {
                firebase.auth().onAuthStateChanged((user) => {
                    resolve(user);
                });
            });

            if (user) {
                const token = await user.getIdToken();
                const fullname = user.displayName;
                const emailAddress = user.email;
                const avatarUrl = user.photoURL;
                const tmpArray = fullname.split(' ');
                const lastName = tmpArray.pop();
                const firstName = tmpArray.join(' ');

                const bearer = 'Bearer ';
                const apiRoot = config.API_SERVER;
                const path = `users?email=${user.email}`;
                const url = apiRoot + path;

                const response = await axios.get(url, { headers: { Authorization: bearer + token } });

                if (response.data[0]) {
                    const userObject = response.data[0];
                    if (userObject.customer_id === null) {
                        const stripePath = 'stripe';
                        const stripeUrl = apiRoot + stripePath;
                        const stripeData = { user: userObject.id };
                        await axios.post(stripeUrl, stripeData, { headers: { Authorization: bearer + token } });
                    }
                } else {
                    const userPath = 'users';
                    const userUrl = apiRoot + userPath;
                    const userData = {
                        username: emailAddress,
                        email: emailAddress,
                        first_name: firstName,
                        last_name: lastName,
                        avatar_url: avatarUrl
                    };
                    await axios.post(userUrl, userData, { headers: { Authorization: bearer + token } });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const firebaseRegister = async (email, password, fname, lname) => {
        try {
            await firebase.auth().createUserWithEmailAndPassword(email, password);

            await firebase
                .auth()
                .currentUser.updateProfile({
                    displayName: `${fname} ${lname}`
                })
                .then(() => {
                    createAccountOrSkip();
                })
                .catch((error) => {
                    console.error('Error updating profile:', error);
                });
        } catch (error) {
            console.error('Error creating user or updating profile:', error);
            // Handle other errors as needed
        }
    };

    const logout = () => firebase.auth().signOut();

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                createAccountOrSkip
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
