// third-party
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';
import { debounce } from 'lodash'; // lodash kütüphanesinden debounce işlevini içe aktarın

// project imports
import axios from 'axios';
import { dispatch } from '../index';
import { config } from '../../config';
import { openSnackbar } from 'store/slices/snackbar';

const initialState = {
    error: null,
    files: [],
    file: null,
    isLoading: false,
    isUploading: false,
    progress: 0
};

const slice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // UPLOAD FILE
        uploadFileSuccess(state, action) {
            // state.file = action.payload;
            state.isUploading = false;
            state.progress = 0;
        },
        // GET FILE
        getFileSuccess(state, action) {
            state.file = action.payload;
            state.isLoading = false;
        },
        // GET FILES
        getFilesSuccess(state, action) {
            state.files = action.payload;
            state.isLoading = false;
        },
        // SET LOADING
        setLoadingSuccess(state, action) {
            state.isLoading = action.payload;
        },
        // SET UPLOADING
        setUploadingSuccess(state, action) {
            state.isUploading = action.payload;
        },
        // SET PROGRESS
        setProgress(state, action) {
            state.progress = action.payload;
        },
        // RESET TEMPLATE
        resetFileSuccess(state) {
            state.files = initialState.files;
            state.file = initialState.file;
            state.progress = initialState.progress;
        }
    }
});

// Reducer
export default slice.reducer;

export function uploadFile(event) {
    return async () => {
        try {
            dispatch(slice.actions.setUploadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const url = `${config.API_SERVER}files/upload`;
            const data = new FormData();
            data.append('email', email);
            data.append('file', event.target.files[0]);
            const response = await axios.post(url, data, {
                headers: {
                    // eslint-disable-next-line prettier/prettier
                    'Authorization': bearer + token,
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
                },
                onUploadProgress: (event) => {
                    const percentCompleted = Math.round((event.loaded * 100) / event.total);
                    dispatch(slice.actions.setProgress(percentCompleted));
                }
            });
            dispatch(slice.actions.uploadFileSuccess(response.data));
            dispatch(
                openSnackbar({
                    open: true,
                    // eslint-disable-next-line prefer-template
                    message: response.data.message,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } catch (error) {
            // Error
            dispatch(slice.actions.setUploadingSuccess(false));
            dispatch(slice.actions.setProgress(0));
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data.message);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data.message));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function getFile(id) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            const bearer = 'Bearer ';
            const includeImageBase64 = '1';
            const url = `${config.API_SERVER}files/${id}?include_image_base64=${includeImageBase64}`;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getFileSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

let debouncedGetFiles = null;

// Debounce işlevini kullanarak isteği geciktiren bir işlev oluşturun
const performGetFiles = async (key, dispatch) => {
    try {
        dispatch(slice.actions.setLoadingSuccess(true));
        const token = await firebase.auth().currentUser.getIdToken();
        const email = firebase.auth().currentUser.email;
        const bearer = 'Bearer ';
        const url = `${config.API_SERVER}files/filterUserFiles?email=${email}&key=${key}`;
        const response = await axios.get(url, { headers: { Authorization: bearer + token } });
        dispatch(slice.actions.getFilesSuccess(response.data));
    } catch (error) {
        // Error
        dispatch(slice.actions.setLoadingSuccess(false));
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data.message);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            dispatch(slice.actions.hasError(error.response.data.message));
            dispatch(
                openSnackbar({
                    open: true,
                    // eslint-disable-next-line prefer-template
                    message: error.response.data.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'The request was made but no response was received',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something happened in setting up the request that triggered an error',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    }
};

// İç içe işlevinizi export edin
export function getFiles(key = '') {
    return async (dispatch) => {
        // Eğer key boşsa, debounce olmadan işlemi hemen gerçekleştir
        if (key === '') {
            performGetFiles(key, dispatch);
        } else {
            // Eğer debounce işlevi daha önce tanımlanmamışsa veya debounce süresi değiştiyse, tanımla
            if (!debouncedGetFiles) {
                debouncedGetFiles = debounce(performGetFiles, 300); // 300 ms gecikme süresi
            }
            // Debounce işlevini çağırın ve dispatch'i iletilen işlevin içinde kullanın
            debouncedGetFiles(key, dispatch);
        }
    };
}

export function resetFile() {
    return async () => {
        try {
            dispatch(slice.actions.resetFileSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'An error occured',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    };
}
