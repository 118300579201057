import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));
const DashboardWallet = Loadable(lazy(() => import('views/dashboard/Wallet')));
const DashboardCatalog = Loadable(lazy(() => import('views/dashboard/Catalog')));
const DashboardTemplate = Loadable(lazy(() => import('views/dashboard/Template')));
const DashboardTemplateAdd = Loadable(lazy(() => import('views/dashboard/Template/Add')));
const DashboardProductDetails = Loadable(lazy(() => import('views/dashboard/Catalog/ProductDetails')));
const DashboardPurchase = Loadable(lazy(() => import('views/dashboard/Purchase')));
const DashboardPurchaseDetails = Loadable(lazy(() => import('views/dashboard/Purchase/PurchaseDetails')));
const DashboardStore = Loadable(lazy(() => import('views/dashboard/Store')));
const DashboardStoreOrders = Loadable(lazy(() => import('views/dashboard/Store/OrderList')));
const AppECommCheckout = Loadable(lazy(() => import('views/application/e-commerce/Checkout')));
const AppECommCheckoutSuccess = Loadable(lazy(() => import('views/application/e-commerce/Checkout/PurchaseComplete')));
const UserProfile = Loadable(lazy(() => import('views/application/users/account-profile/Profile2')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        {
            path: '/dashboard/wallet',
            element: <DashboardWallet />
        },
        {
            path: '/dashboard/catalog',
            element: <DashboardCatalog />
        },
        {
            path: '/dashboard/catalog/product-details/:id',
            element: <DashboardProductDetails />
        },
        {
            path: '/dashboard/template',
            element: <DashboardTemplate />
        },
        {
            path: '/dashboard/template/add/:id',
            element: <DashboardTemplateAdd />
        },
        {
            path: '/dashboard/purchase',
            element: <DashboardPurchase />
        },
        {
            path: '/dashboard/purchase/:id',
            element: <DashboardPurchaseDetails />
        },
        {
            path: '/dashboard/store',
            element: <DashboardStore />
        },
        {
            path: '/dashboard/store/:id/orders',
            element: <DashboardStoreOrders />
        },
        {
            path: '/e-commerce/checkout',
            element: <AppECommCheckout />
        },
        {
            path: '/e-commerce/success',
            element: <AppECommCheckoutSuccess />
        },
        {
            path: 'user/account-profile/profile2',
            element: <UserProfile />
        }
    ]
};

export default MainRoutes;
